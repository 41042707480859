<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <b-row>
      <b-col md="8">
        <b-card id="status" :title="$t('Status')">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="7">
                <b-form-group :label="$t('Order Situation') +`:`" label-cols-md="12">
                  <div class="d-flex align-items-center h-100">
                    <h3>
                      {{ model.status }}
                    </h3>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Submit -->
              <b-col md="5" class="text-right">
                <div
                  v-for="(item, index) in model.next_status"
                  :key="index"
                  :class="{ 'mt-1': index > 0 }"
                >
                  <b-button
                    :variant="item.color"
                    type="submit"
                    class="text-right"
                    @click.prevent="handlerUpdateStatus(item.id)"
                  >
                    {{ item.key }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card id="billing" :title="$t('Billing Information')">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group :label="$t('Order ID')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.data.order_no }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label="$t('Order date')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ getTimestampDate(true, '/', model.data.created_at) }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="12">
                    <b-form-group :label="$t('Phone number')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.seller.phone }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label="$t('Recieving address')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.seller.address }}
                      </div>
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group :label="$t('Shipping service')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.shipping_and_payment.checkout_shipping_carrier }} | {{ model.shipping_and_payment.shipping_method }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label="$t('Payment method')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.shipping_and_payment.payment_method }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="12">
                    <b-form-group :label="$t('Payment status')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        <PaymentStatus :value="model.shipping_and_payment.payment_status" />
                      </div>
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </b-col>
            </b-row>
            <CustomTable
              :items="model.list"
              :fields="fields"
              :add_new_button="false"
              :sort_button="false"
              custom-link="product/edit"
            />
            <div class="d-flex justify-content-end">
              <div class="invoice-total-wrapper">
                <!-- <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Subtotal') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ currencyFormat(model.bill.sub_total) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Discount') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ currencyFormat(model.bill.discount_price) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Coupon') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ model.bill.coupon_code }}
                  </p>
                </div> -->
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Doanh thu') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ unitFormatOriginal(model.bill.escrow_amount) }}
                  </p>
                </div>
                <hr class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Khách hàng trả') }}:
                  </p>
                  <strong class="invoice-total-amount">
                    {{ unitFormatOriginal(model.bill.total_amount) }}
                  </strong>
                </div>
              </div>
            </div>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="4">
        <Fieldset id="buyer" :title="$t('Buyer')">
          <b-form-group :label="$t('Name')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              {{ model.buyer.name }}
            </div>
          </b-form-group>
          <b-form-group :label="$t('Phone number')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              {{ model.buyer.phone }}
            </div>
          </b-form-group>
          <b-form-group :label="$t('Address')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              {{ model.buyer.address }}
            </div>
          </b-form-group>

          <!-- <b-form-group :label="$t('Bill order')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              <a :href="model.buyer.img_bill" target="_blank">
                <img :src="model.buyer.img_bill" alt="" height="100">
              </a>
            </div>
          </b-form-group> -->
        </Fieldset>
        <!-- <Fieldset id="history" :title="$t('Order history')">
          <timeline>
            <timeline-item
              v-for="(item, index) in model.history_status"
              :key="index"
              bg-color="#53bfaf"
              :hollow="false"
            >
              <span :class="`text-${item.status.color}`">{{
                item.status.key
              }}</span>
              <br>
              {{ item.created_at }}
            </timeline-item>
          </timeline>
        </Fieldset> -->
      </b-col>
    </b-row>
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable object-curly-newline */
import { BRow, BCol, BFormGroup, BButton, BForm, BCard } from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import statusList from '@/mixins/status.json'
// import { Timeline, TimelineItem } from 'vue-cute-timeline'
// import OrderStatus from '@/views/components/order-status/index.vue'
// import PaymentStatus from '@/views/components/order-status/payment_status.vue'
// import PaymentMethod1 from '@/views/components/order-status/payment_method.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    // Timeline,
    // TimelineItem,
    BCard,
    // OrderStatus,
    // PaymentStatus,
    // PaymentMethod1,
    // TimelineTitle,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        data: null,
        order_no: null,
        created_at: null,
        list: [],
        next_status: [],
        history_status: [],
        buyer: {},
        seller: {},
        shipping_and_payment: {},
        bill: {},
      },
      fields: [
        { key: 'no', label: 'No', sortable: false },
        // { key: 'avatar', label: 'Image', sortable: true },
        // { key: 'name', label: 'Product name', sortable: true },
        { key: 'name_original', label: 'Product name', sortable: true },
        // { key: 'sku', label: 'SKU', sortable: true },
        { key: 'quantity', label: 'Quantity', sortable: false },
        { key: 'price1', label: 'Unit price', sortable: false },
        { key: 'subTotal', label: 'Sub total', sortable: false },
      ],
      key: 'buso_client_page_config',
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_order_detail?ordersn_list[]=${this.$route.params.id}&site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model.next_status = []
            this.model.list = []
            this.model.history_status = []

            this.model.data = {
              order_no: res.data.data[0].ordersn,
              created_at: res.data.data[0].create_time,
            }

            this.model.shipping_and_payment = {
              shipping_method: res.data.data[0].shipping_carrier,
              payment_method: res.data.data[0].payment_method,
              checkout_shipping_carrier: res.data.data[0].checkout_shipping_carrier,
            }

            this.model.status = res.data.data[0].order_status

            res.data.data[0].items.map((val, index) => {
              this.model.list.push({
                no: index + 1,
                id: val.item_id,
                // avatar: val.avatar_product,
                name_original: val.item_name,
                // name_product_attribute: val.name_product,
                // name_attribute: val.name_attribute,
                sku: val.item_sku,
                quantity: val.variation_quantity_purchased,
                price1: val.variation_original_price,
                subTotal: Number(val.variation_quantity_purchased) * Number(val.variation_original_price),
                total: val.variation_original_price,
              })
            })

            this.model.bill = {
              escrow_amount: Number(res.data.data[0].escrow_amount),
              total_amount: Number(res.data.data[0].total_amount),
            }

            this.model.buyer = {
              name: res.data.data[0].recipient_address.name,
              phone: res.data.data[0].recipient_address.phone,
              address: res.data.data[0].recipient_address.full_address,
            }

            // this.model.seller = await this.loadConfig()
            // if (
            //   JSON.parse(res.data.data.history_status) !== null &&
            //   JSON.parse(res.data.data.history_status).length > 0
            // ) {
            //   JSON.parse(res.data.data.history_status).map(val => {
            //     this.model.history_status.push({
            //       status: statusList.filter(
            //         x => x.id === Number(val.status),
            //       )[0],
            //       created_at: val.created_at,
            //     })
            //   })
            // }
            // if (
            //   JSON.parse(res.data.data.next_status) !== null &&
            //   JSON.parse(res.data.data.next_status).length
            // ) {
            //   JSON.parse(res.data.data.next_status).map(val => {
            //     this.model.next_status.push(
            //       statusList.filter(x => x.id === Number(val))[0],
            //     )
            //   })
            // }
            // // this.model.next_status = JSON.parse(res.data.data.next_status)
            // this.model.status = statusList.filter(
            //   x => x.id === res.data.data[0].status,
            // )[0]
            // this.model.data = {
            //   order_no: res.data.data[0].order_no,
            //   created_at: res.data.data[0].created_at,
            // }
            // this.model.buyer = {
            //   fullname: res.data.data[0].fullname,
            //   address: res.data.data[0].address,
            //   phone: res.data.data[0].phone,
            //   email: res.data.data[0].email,
            //   img_bill: res.data.data[0].img_bill,
            // }
            // let shippingMethod = '-- none --'
            // if (res.data.data[0].shipping_status === 0 || res.data.data[0].shipping_status === 1) {
            //   shippingMethod = 'Cửa hàng vận chuyển'
            // }
            // if (res.data.data[0].shipping_status === 3) {
            //   shippingMethod = 'Giao hàng nhanh'
            // }
            // this.model.shipping_and_payment = {
            //   shipping_method: shippingMethod,
            //   payment_method: res.data.data[0].payment_name_text !== '' && res.data.data[0].payment_name_text !== null && res.data.data[0].payment_name_text !== undefined ? res.data.data[0].payment_name_text : res.data.data[0].payment_type_text,
            //   payment_status: res.data.data[0].payment_status_text,
            // }
            // this.model.bill = {
            //   coupon_code: res.data.data[0].coupon_code,
            //   discount_price: Number(res.data.data[0].coupon_discount),
            //   shipping_price: Number(res.data.data[0].shipping_price),
            //   sub_total: Number(res.data.data[0].total),
            //   total: Number(res.data.data[0].total) + Number(res.data.data[0].shipping_price) - Number(res.data.data[0].coupon_discount),
            // }
            // res.data.data[0].list.map((val, index) => {
            //   this.model.list.push({
            //     no: index + 1,
            //     id: val.product_id_code,
            //     avatar: val.avatar_product,
            //     name: val.name_product,
            //     name_product_attribute: val.name_product,
            //     name_attribute: val.name_attribute,
            //     sku: val.sku_product,
            //     quantity: val.quantity,
            //     price: val.price,
            //     subTotal: Number(val.quantity) * Number(val.price),
            //     total: val.total,
            //   })
            // })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadConfig() {
      let seller = {}
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/setting/${this.key}?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const data = JSON.parse(res.data.data.value)
            seller = {
              phone: data.filter(x => x.key === 'phone')[0].value,
              address: data.filter(x => x.key === 'address')[0].value,
            }
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return seller
    },
    async handlerUpdateStatus(status) {
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          status,
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/order/update_status/${this.$route.params.id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Update Trademark success',
              },
            })
            this.loadDetail()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
.invoice-total-wrapper {
  width: 250px;

  .invoice-total-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
</style>
